
thead button {
    border: 0;
    border-style: none;
    font-weight: bold;
    background-color: transparent;
}

thead button.ascending::after {
    content: '▲';
    display: inline-block;
    margin-left: 5px;
}

thead button.descending::after {
    content: '▼';
    display: inline-block;
    margin-left: 5px;
}

